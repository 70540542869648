.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon-container i {
    font-size: 28px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out; 
  }
  
  .icon-container i.hover-effect:hover {
    transform: translateY(-2px); 
  }
  
  .icon-container i.selected {
    color: rgba(47, 47, 104, 0.979); 
  }
  
  .back-btn {
    transition: transform 0.2s ease-in-out;
  }
  
  .back-btn:hover {
    transform: scale(1.0); 
  }
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
      transform: scale(0.);
    }
    50% {
      opacity: 1;
      transform: scale(1.1);
    }
  }
  .animated-text {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    animation: fadeInOut 2s infinite;
    color: #ff6f61; 
  }