//
// vector-maps.scss
//

.jvm-tooltip {
    border-radius: 3px;
    background-color: var(--#{$prefix}primary);
    font-family: $font-family-base;
    box-shadow: $box-shadow-lg;
    padding: 5px 9px;
}

.jvm-container {
    text {
        font-family: $font-family-base;
        font-size: $font-size-base;
        fill: var(--#{$prefix}body-color);
    }
}

.jvm-zoom-btn {
    background-color: var(--#{$prefix}primary);
}


.custom-vector-map {
    svg {
        width: 100%;
        max-height: 100%;

        path {
            fill: var(--#{$prefix}light) !important;
        }
    }
}