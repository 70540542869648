/* Competitor.css */

.competitor-table {
    margin: 40px auto;
    width: 80%;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
}
.competitor-table h2 {
    text-align: center;
    font-size: 2.5em;        
    font-weight: bold;       
    color: #2C3E50;          
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding-bottom: 10px;
    border-bottom: 3px solid #4CAF50; 
    margin-bottom: 25px;
}

/* Table styling */
.competitor-table table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    table-layout: fixed;
}

/* Feature and Competitor Headers - Darkened and larger */
.competitor-table th.feature-header,
.competitor-table th.competitor-header {
    font-size: 1.5em;         
    font-weight: 700;         
    color: #222;              
    background-color: #f8f8f8;
    padding: 15px 10px;
    text-transform: capitalize;
}
.competitor-table th,
.competitor-table td {
    padding: 12px;
    border: 1px solid #e0e0e0;
    text-align: center;
    vertical-align: middle;
}

/* Status circles with shadow */
.status-circle {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.status-circle.available {
    background-color: #4CAF50; /* Green for available */
}

.status-circle.premium {
    background-color: #FFC107; /* Yellow for premium */
}

.status-circle.not-available {
    background-color: #E53935; /* Red for not available */
}
.status-circle.not {
    background-color: #E53935; /* Red for not available */
}
