.input-card {
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: none;
}

.chat-input-container {
  padding: 10px;
  border-radius: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
}

.chat-input {
  padding: 10px;
  width: 100%;
  /* height: 52px; */
  max-height: 160px !important; 
  padding-left: 30px;
  border: none;
  outline: none;
  resize: none;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 50px;
  background-color: #f7f7f8;
  overflow-y: auto;
}

.chat-input:focus {
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.chat-input::placeholder {
  color: #999;
}


.send-button {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  color: white;
  margin-left: 10px;
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button.enabled {
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}

.send-button i {
  font-size: 18px;
}



